<template>
    <section>
        <div class="container">
            <TitleBar title="İrsaliye Kayıtları" :modi="modi" />

            <b-field class="columns box" label="Filtre Alanları" grouped group-multiline>
                <b-field class="column is-2" label="ID">
                    <b-input v-model="invoices.filters.id"></b-input>
                </b-field>
                <b-field class="column is-3" label="Irsaliye Türü">
                    <b-select placeholder="Seçiniz.." v-model="invoices.filters.class" expanded>
                        <option value="Giriş İrsaliyesi">Giriş İrsaliyesi</option>
                        <option value="Çıkış İrsaliyesi">Çıkış İrsaliyesi</option>
                    </b-select>
                </b-field>
                <b-field class="column is-7" label="Açıklama">
                    <b-input v-model="invoices.filters.name"></b-input>
                </b-field>
                <b-field class="column is-4" label="Müşteri/Tedarikçi Unvan">
                    <CustomerAutofill v-model="invoices.filters.customer" />
                </b-field>
                <b-field class="column is-4" label="Sevk Tarihi">
                    <b-datepicker placeholder="Tarih aralığı seçin.." v-model="invoices.filters.shippedat" range> </b-datepicker>
                </b-field>
                <b-field class="column is-4" label="Düzenleme Tarihi">
                    <b-datepicker placeholder="Tarih aralığı seçin.." v-model="invoices.filters.issuedat" range> </b-datepicker>
                </b-field>
            </b-field>

            <b-field class="columns box mt-2" label="Irsaliye Tablosu" grouped group-multiline>
                <b-field class="column is-12" grouped group-multiline>
                    <b-button class="column" type="is-info is-light" icon-left="magnify" label="Ara" @click="invoices_read" />
                    <b-button class="column ml-1" type="is-success is-light" icon-left="plus" label="Yeni" @click="invoices_new" />
                    <b-button class="column ml-1" type="is-success is-light" icon-left="content-copy" label="Ref. Al" @click="invoices_ref" disabled/>
                    <b-button class="column ml-1" type="is-info is-light" icon-left="eye" label="Görüntüle" :disabled="!invoices.selected.id" @click="invoices_show" />
                    <b-button class="column ml-1" type="is-warning is-light" icon-left="pencil" label="Düzenle" :disabled="!invoices.selected.id" @click="invoices_edit" />
                    <b-button class="column ml-1" type="is-danger is-light" icon-left="delete" label="Sil" :disabled="!invoices.selected.id" @click="invoices_delete" />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="printer" label="Yazdır" @click="invoices_print" disabled />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="import" label="Içe Aktar" disabled />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="export" label="Dışa Aktar" @click="invoices_export" disabled />
                </b-field>

                <b-table
                    class="column is-12"
                    :data="invoices.data"
                    :total="invoices.total"
                    :mobile-cards="false"
                    :current-page.sync="invoices.page"
                    :selected.sync="invoices.selected"
                    :sort-multiple-data.sync="invoices.sorts"
                    :loading="$apollo.queries.readInvoices.loading"
                    @dblclick="invoices_show"
                    @sort="invoices_sort"
                    @sorting-priority-removed="invoices_nosort"
                    backend-pagination
                    backend-sorting
                    sort-multiple
                    striped
                    hoverable
                    narrowed
                    paginated
                    pagination-simple
                >
                    <template slot="empty">
                        <div class="is-12 has-text-centered">İrsaliye kaydı bulunamadı.</div>
                    </template>
                    <b-table-column field="id" label="ID" v-slot="props" sortable>{{ props.row.id.slice(-4) }}</b-table-column>
                    <b-table-column field="class" label="İrsaliye Türü" v-slot="props" sortable>{{ props.row.class }}</b-table-column>
                    <b-table-column field="customer.class" label="Müşteri Grubu" v-slot="props">{{ props.row.customer.class }}</b-table-column>
                    <b-table-column field="customer.name" label="Müşteri/Tedarikçi Unvan" v-slot="props">{{ props.row.customer.name }}</b-table-column>
                    <b-table-column field="shippedat" label="Sevk Tarihi" v-slot="props" sortable>{{
                        new Date(props.row.shippedat).toLocaleDateString("tr-TR", {
                            weekday: "long",
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                        })
                    }}</b-table-column>
                    <b-table-column field="issuedat" label="Düzenleme Tarihi" v-slot="props" sortable>{{
                        new Date(props.row.issuedat).toLocaleDateString("tr-TR", {
                            weekday: "long",
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                        })
                    }}</b-table-column>
                </b-table>
            </b-field>
        </div>
    </section>
</template>

<script>
import gql from "graphql-tag";
import TitleBar from "@/components/TitleBar.vue";
import CustomerAutofill from "@/components/CustomerAutofill.vue";

export default {
    name: "InvoiceTable",
    components: {
        TitleBar,
        CustomerAutofill,
    },
    data: () => ({
        modi: "",
        invoices: {
            page: 1,
            take: 20,
            total: 9999,
            data: [],
            sorts: [],
            filters: {
                customer: {},
                shippedat: [],
                issuedat: [],
            },
            selected: {},
        },
    }),
   mounted(){
        this.invoices_read();
    },
    methods: {
        invoices_read() {
            this.$apollo.queries.readInvoices.start();
            this.$apollo.queries.readInvoices.refetch();
        },
        invoices_new() {
            this.$router.push({ path: "/irsaliye/ekle" });
        },
        invoices_ref() {
            this.$router.push({
                path: "/irsaliye/ekle",
                query: { id: this.invoices.selected.id, modi: "ref" },
            });
        },
        invoices_show() {
            this.$router.push({
                path: "/irsaliye/detay",
                query: { id: this.invoices.selected.id, modi: "show" },
            });
        },
        invoices_edit() {
            this.$router.push({
                path: "/irsaliye/detay",
                query: { id: this.invoices.selected.id, modi: "edit" },
            });
        },
        invoices_delete() {},
        invoices_print() {},
        invoices_export() {},
        invoices_sort(field, order) {
            this.invoices_nosort(field);
            this.invoices.sorts.push({ field, order });
        },
        invoices_nosort(field) {
            this.invoices.sorts.forEach((e, i, a) => {
                if (e.field == field) a.splice(i, 1);
            });
        },
    },
    apollo: {
        readInvoices: {
            query: gql`
                query readInvoices($config: String) {
                    readInvoices(config: $config) {
                        id
                        name
                        class
                        customer {
                            id
                            class
                            name
                        }
                        invoaddr
                        shipaddr
                        shippedat
                        issuedat
                    }
                }
            `,
            variables() {
                var sorts = [];
                this.invoices.sorts.forEach((e) => sorts.push({ column: e.field, order: e.order }));
                for (var filter in this.invoices.filters) if (!this.invoices.filters[filter]) delete this.invoices.filters[filter];

                return {
                    config: JSON.stringify({
                        sorts,
                        take: this.invoices.take,
                        filters: this.invoices.filters,
                        skip: (this.invoices.page - 1) * this.invoices.take,
                    }),
                };
            },
            update(data) {
                this.invoices.data = data.readInvoices;
            },
        },
    },
};
</script>
